const deDe = {
    global: {
        forgotPassword: "Passwort vergessen?",
        forgotPasswordContact: "Haben Sie Ihr Passwort vergessen? Wenden Sie sich bitte an den Digital Sales Assistant-Service.",
        retailSuiteApps: "Retail Suite-Apps",
        signIn: "Einloggen",
        tipsAndTricks: "Tipps",
        welcomePortal: "Willkommen auf dem Portal.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "DSA",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "OK",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Wird geladen...",
        },
        error: {
            organizationNameNotFound: `'organizationName' nicht gefunden`,
            organizationNotFound: `'organization' nicht gefunden`,
        },
    },
};

export default deDe;
