const nlNl = {
    global: {
        forgotPassword: "Wachtwoord vergeten?",
        forgotPasswordContact: "Bent u uw wachtwoord vergeten? Neem contact op met de Digital Sales Assistant service.",
        retailSuiteApps: "Retail Suite apps",
        signIn: "Inloggen",
        tipsAndTricks: "Tips & tricks",
        welcomePortal: "Welkom op het portaal.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "DSA",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "OC",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. In ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementum cursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Laden...",
        },
        error: {
            organizationNameNotFound: `'organizationName' niet gevonden`,
            organizationNotFound: `'organization' niet gevonden`,
        },
    },
};

export default nlNl;
