const frFr = {
    global: {
        forgotPassword: "Mot de passe oublié?",
        forgotPasswordContact: "Avez-vous oublié votre mot de passe? Veuillez contacter le service Digital Sales Assistant.",
        retailSuiteApps: "Applications Retail Suite",
        signIn: "S'identifier",
        tipsAndTricks: "Conseils & Astuces",
        welcomePortal: "Bienvenue sur le portail.",
    },
    tipsTricks: {
        nhg: {
            subject: "NHG",
            teaser: "NHG Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        dsa: {
            subject: "AVD",
            teaser: "DSA Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
        oc: {
            subject: "CO",
            teaser: "OC orem ipsum dolor sit amet, consectetur adipiscing elit. Dans ornare, ante vitae eleifend blandit, quam risus tincidunt ligula, elementumcursus purus justo id felis. Curabitur.",
        },
    },
    messages: {
        loading: {
            default: "Chargement...",
        },
        error: {
            organizationNameNotFound: `'organizationName' introuvable`,
            organizationNotFound: `'organization' introuvable`,
        },
    },
};

export default frFr;
